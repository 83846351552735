@import "./index";

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    min-width: 1366px;
    font-size: 14px;
    font-family: PingFangSC-Regular, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    width: 100%;
}

a {
    text-decoration: none;
    &:hover {
        color: #127FF9;
    }
}

.pr {
    position: relative;
}

.link {
    color: #127FF9;
    text-decoration: none;
    &:hover {
        color: #127FF9;
        text-decoration: underline;
    }
    &:active {
        color: #127FF9;
        text-decoration: none;
    }
    &:disabled {
        opacity: .4;
    }
}

.bai {
    background: #FFF;
}

.hui {
    background: #F6F8FB;
}

.section {
    padding: 60px 0 120px;
}
.section1 {
    padding: 60px 0 40px;
}
.section2 {
    padding: 40px 0 80px;
}

.flex {
    display: flex;
}

.flex-jus-con {
    display: flex;
    justify-content: center;
}

.flex-ali-con {
    display: flex;
    align-content: center;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 36px;
    background: #FFF;
    border: 1px solid #127FF9;
    border-radius: 4px;
    font-size: 16px;
    color: #127FF9;
    cursor: pointer;
    &:hover {
        background: rgba(18, 127, 249, .1);
    }
    &:active {
        background: rgba(18, 127, 249, .2);
    }
    &.disable {
        color: #127FF9;
    }
}

.btnStrong {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 36px;
    background: #127FF9;
    border-radius: 4px;
    font-size: 16px;
    color: #FFF;
    cursor: pointer;
    &:hover {
        color: #FFF;
        opacity: .7;
        background: #127FF9;
    }
    &:active {
        background: #0265F2;
    }
    &.disable {
        cursor: default;
        background: #127FF9;
    }
}

.ant-pagination.mini {
    text-align: center;
    .ant-pagination-item {
        margin-right: 8px;
        line-height: 24px;
        border-radius: 2px;
        font-family: $pageFont;
        a {
            line-height: 24px;
            color: rgba(25, 28, 61, .5);
        }
        &:hover {
            a {
                color: #127FF9;
            }
        }
    }
    .ant-pagination-item.ant-pagination-item-active {
        background: #127FF9;
        &:hover {
            border: none;
        }
        a {
            color: #FFF;
        }
    }
    .ant-pagination-jump-prev-custom-icon .anticon svg {
        color: #127FF9;
    }
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
        font-size: 12px;
        line-height: 32px;
        font-family: $pageFont;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
        margin-right: 8px;
        line-height: 22px;
        background: center no-repeat;
        .ant-pagination-item-link {
            border: 1px solid rgba(25, 28, 61, .2);
            border-radius: 2px;
            &:hover {
                border: 1px solid rgba(25, 28, 61, .5);
            }
        }
        .anticon {
            display: none;
        }
    }
    .ant-pagination-prev {
        background-image: url(./asset/img/news/left.svg);
        &:hover {
            background-image: url(./asset/img/news/leftHover.svg);
        }
    }
    .ant-pagination-next {
        background-image: url(asset/img/news/right.svg);
        &:hover {
            background-image: url(asset/img/news/rightHover.svg);
        }
    }
}

.page-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 52px;
    margin: 0 auto;
    font-size: 18px;
    color: #191C3D;
    cursor: pointer;
    border: 1px solid rgba(25, 28, 61, .5);
    border-radius: 4px;
    &:hover {
        color: #127FF9;
        background: rgba(18, 127, 249, .1);
        border: 1px solid #127FF9;
    }
    &:active {
        background: rgba(18, 127, 249, .2);
    }
    &.disable {
        color: #127FF9;
    }
}
@media (max-width: 768px) {
    .section {
        padding: 1rem 0 1.5rem;
    }
}

@media (max-width: 768px) {
    body {
        width: 100%;
        min-width: 100%;
    }
}